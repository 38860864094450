import React from 'react'
import Layout from 'components/Layout/Layout'
import styled from 'styled-components'
import PPCBanner from 'components/PPCBanner/PPCBanner'
import PPCService from 'components/PPCService/PPCService'
import ProcessFlow from 'components/ProcessFlow/ProcessFlow'
import CaseStudySlider from 'components/CaseStudySlider/CaseStudySlider'
import CustomerReviewSlider from 'components/CustomerReviewSlider/CustomerReviewSlider'
import FeatureResources from 'components/FeatureResources/FeatureResources'
import PPCGetInTouch from 'components/PPCGetInTouch/PPCGetInTouch'
import { EcommerceDevComProps } from 'components/Props/lp/ecommerce-development-company'
import { lg, sm, md } from 'config/variables'
import BuildApplication from 'components/BuildApplication/BuildApplication'
import { graphql } from 'gatsby'
import SEO from 'components/Seo'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'

export const EcommerceDevComPage = styled.div`
  .ppcbanner {
    .mobile-btn-row {
      ${md(`
        display: block;
      `)}
    }
  }
  .benefit-work-sec {
    padding: 108px 0 55px;
    ${lg(`
      padding: 70px 0 50px;
    `)}
    ${sm(`
      padding: 50px 0 0px;
    `)}
  }
  .feature-resource-section {
    margin-bottom: 100px;
    background: transparent;
    ${lg(`
      margin-bottom: 50px;
    `)}
    ${md(`
      margin-bottom: 20px;
    `)} 
    .feature-listing {
      padding-bottom: 50px;
    }
    .contact-info-card {
      display: none;
    }
  }
`

const EcommerceDevCom = props => {
  const { location } = props
  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })
  let processFlowItem = [
    props.data.process1.childImageSharp.fluid,
    props.data.process2.childImageSharp.fluid,
    props.data.process3.childImageSharp.fluid,
  ]
  let posterImage = [props.data.posterThumbImage.childImageSharp.fluid]
  return (
    <Layout location={location}>
      <SEO
        title="Ecommerce Development Company"
        keywords={[`simform`]}
        meta={[
          {
            name: 'ROBOTS',
            content: 'NOINDEX, NOFOLLOW',
          },
        ]}
      />
      <EcommerceDevComPage>
        <PPCBanner ppcbannerdata={EcommerceDevComProps} {...props} />
        <BuildApplication
          buildApplicationData={EcommerceDevComProps}
          posterImage={posterImage}
        />
        <div className="lazy-load-div">
          <PPCService ppcServicedata={EcommerceDevComProps} />
        </div>
        {showContent && (
          <>
            <CaseStudySlider caseStudydata={EcommerceDevComProps} {...props} />
            <CustomerReviewSlider
              customReviewSliderdata={EcommerceDevComProps}
              {...props}
            />
            <ProcessFlow
              ProcessFlowData={EcommerceDevComProps.workingBenefitsProps}
              items={processFlowItem}
            />
            <PPCGetInTouch ppcGetTouchData={EcommerceDevComProps} />
            <FeatureResources
              featureresourceData={EcommerceDevComProps}
              {...props}
            />
          </>
        )}
      </EcommerceDevComPage>
    </Layout>
  )
}

export default EcommerceDevCom

export const query = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/ecommerce-banner@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 519) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case1: file(relativePath: { regex: "/case-onlymob@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case2: file(relativePath: { regex: "/case-safari@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case3: file(relativePath: { regex: "/case-ipad@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case4: file(relativePath: { regex: "/case-mobile@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case5: file(relativePath: { regex: "/redbull-case@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication1: file(
      relativePath: { regex: "/blog-build-ecommerce@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication2: file(
      relativePath: { regex: "/blog-ecommerce-reactjs@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication3: file(
      relativePath: { regex: "/blog-ecommerce-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process1: file(relativePath: { regex: "/automation@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 408) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process2: file(relativePath: { regex: "/mobile-app@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 408) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process3: file(relativePath: { regex: "/manual-qa@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 408) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    olivia: file(relativePath: { regex: "/olivia.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    justin: file(relativePath: { regex: "/justin.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ritwik: file(relativePath: { regex: "/ritwik.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    danielle: file(relativePath: { regex: "/danielle.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jawann: file(relativePath: { regex: "/jawann.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jansen: file(relativePath: { regex: "/jansen.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jeff: file(relativePath: { regex: "/Jeff.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    andy: file(relativePath: { regex: "/andy.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    posterThumbImage: file(relativePath: { regex: "/poster-img.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
